import axios from "axios/index";
// import createAuthRefreshInterceptor from "axios-auth-refresh";

import ServiceStorage from "./../services/service.storage";

import { ConfigLink } from "../routes/configLinks";

const env = process.env;
const ServiceApi = axios;

ServiceApi.defaults.timeout = 15000;
ServiceApi.defaults.baseURL = env.REACT_APP_API_URL;

ServiceApi.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

ServiceApi.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    //console.error(error);
    if (error.response) {
      if ([401].includes(error.response.status)) {
        window.location.replace(`#${ConfigLink.accountLogin}`);
        console.error("should redirect to login");
      }
    }
    return Promise.reject(ServiceApi.getErrorText(error));
    //return Promise.reject(error);
  },
);

ServiceApi.setToken = () => {
  const access_token = ServiceStorage.getAccessToken();
  if (access_token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
  } else {
    axios.defaults.headers.common["Authorization"] = "";
    delete axios.defaults.headers.common["Authorization"];
  }
};

ServiceApi.logout = () => {
  ServiceStorage.clearToken();
  ServiceApi.setToken();
};

ServiceApi.getErrorText = (ex) => {
  let errorText = "An unknown error occurred.";
  if (ex === undefined) {
    //errorText = errorText;
  } else if (ex.response === undefined) {
    errorText = "Can not connect to server.";
  } else if (ex.response.status === 403) {
    errorText = "You have not required role to do this.";
  } else if (
    ex.response.data !== undefined &&
    ex.response.data.error_description !== undefined
  ) {
    errorText = ex.response.data.error_description;
  } else if (ex.response.data !== undefined && ex.response.data.message !== undefined) {
    errorText = ex.response.data.message;
  }

  if (errorText && typeof errorText === "object" && errorText.constructor === Array) {
    errorText = errorText.join("\n");
  }

  return errorText;
  // TODO: replace above line with below line, apiError use in catch for show api error else error related with another lines
  // return {msg: errorText, status: ex.response.status, apiError: true };
};

ServiceApi.hasToken = () => {
  const access_token = ServiceStorage.getAccessToken();
  if (access_token) {
    return true;
  } else {
    return false;
  }
};

// const refreshAuthLogic = (failedRequest) => {
//   const refreshToken = ServiceStorage.getRefreshToken();
//   ServiceApi.post(`${env.REACT_APP_API_URL}/auth/refresh-token`, { refreshToken }).then(
//     (tokenRefreshResponse) => {
//       console.log("tokenRefreshResponse :>> ", tokenRefreshResponse);
//       ServiceStorage.setToken(
//         tokenRefreshResponse.access.token,
//         tokenRefreshResponse.refresh.token,
//       );

//       failedRequest.response.config.headers["Authorization"] =
//         "Bearer " + tokenRefreshResponse.access.token;

//       return Promise.resolve();
//     },
//   );
// };

// createAuthRefreshInterceptor(ServiceApi, refreshAuthLogic);

ServiceApi.setToken();

export default ServiceApi;
