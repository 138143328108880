import React from "react";
import Loader from "react-loader-spinner";

const Loading = ({ shape = "Audio" }) => {
  return (
    <div
      style={{
        display: "flex",
        overflowY: "auto",
        flexDirection: "auto",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "stretch",
      }}
    >
      <Loader type={shape} color="#b80000" height={50} width={50} />
    </div>
  );
};

export { Loading };
