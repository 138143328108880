import { SHA256, AES, enc } from "crypto-js";
import SecureStorage from "secure-web-storage";

const env = process.env;

let SECRET_KEY = env.REACT_APP_LOCAL_STORAGE_KEY;

let secureStorage = new SecureStorage(localStorage, {
  hash: (key) => {
    key = SHA256(key, SECRET_KEY);
    return key.toString();
  },
  encrypt: (data) => {
    data = AES.encrypt(data, SECRET_KEY);
    data = data.toString();

    return data;
  },
  decrypt: (data) => {
    data = AES.decrypt(data, SECRET_KEY);
    data = data.toString(enc.Utf8);

    return data;
  },
});

export default secureStorage;
