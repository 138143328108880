const env = process.env;

const configApp = {
  accessToken: "accessToken",
  refreshToken: "refreshToken",

  userInfo: "userInfo",
  apiUrl: env.REACT_APP_API_URL,
};

export default configApp;
