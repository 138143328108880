import React from "react";
import { Switch, Route, HashRouter, Redirect } from "react-router-dom";
import { Loading } from "./ui/components/Loading";
import { ConfigLink } from "./core/routes/configLinks";
import ProviderApp from "./core/providers/provider.app";
import ContextApp from "./core/contexts/context.app";

// account
const Login = React.lazy(() => import("./ui/views/account/Login"));
const SignUp = React.lazy(() => import("./ui/views/account/SignUp"));
const SignUpInvited = React.lazy(() => import("./ui/views/account/SignUpInvited"));
const SetPassword = React.lazy(() => import("./ui/views/account/SetPassword"));
const ForgotPassword = React.lazy(() => import("./ui/views/account/ForgotPassword"));
const ResetPassword = React.lazy(() => import("./ui/views/account/ResetPassword"));
const VerifyEmailAddress = React.lazy(() =>
  import("./ui/views/account/VerifyEmailAddress"),
);

// Portal Layout
const PortalLayout = React.lazy(() => import("./ui/views/_layout/PortalLayout"));

function App() {
  return (
    <ProviderApp>
      <ContextApp.Consumer>
        {(context) => {
          if (process.env.REACT_APP_DEVELOPMENT_MODE) {
            console.log("context :>> ", context);
          }

          return (
            <HashRouter>
              <React.Suspense fallback={<Loading />}>
                <Switch>
                  <Route
                    name="Login"
                    path={ConfigLink.accountLogin}
                    component={Login}
                    exact
                  />
                  <Route
                    name="SignUp"
                    path={ConfigLink.accountSignUp}
                    component={SignUp}
                  />
                  <Route
                    name="SignUpInvited"
                    path={`${ConfigLink.accountSignUpInvited}/:email/:token`}
                    component={SignUpInvited}
                  />
                  <Route
                    name="SetPassword"
                    path={`${ConfigLink.accountSetPassword}/:email/:token`}
                    component={SetPassword}
                  />
                  <Route
                    name="ForgotPassword"
                    path={ConfigLink.accountForgotPassword}
                    component={ForgotPassword}
                  />

                  <Route
                    name="ResetPassword"
                    path={`${ConfigLink.accountResetPassword}/:email/:token`}
                    component={ResetPassword}
                  />

                  <Route
                    name="VerifyEmailAddress"
                    path={`${ConfigLink.accountVerifyEmail}/:email`}
                    component={VerifyEmailAddress}
                  />

                  {context.isAuth ? (
                    <React.Fragment>
                      <Route name="portal" component={PortalLayout} />
                    </React.Fragment>
                  ) : (
                    <Redirect to={ConfigLink.accountLogin} />
                  )}
                </Switch>
              </React.Suspense>
            </HashRouter>
          );
        }}
      </ContextApp.Consumer>
    </ProviderApp>
  );
}

export default App;
