const ConfigLink = {
  setParam: (url, paramName, paramValue) => {
    return url.replace(paramName, paramValue);
  },
  accountLogin: "/account/login",
  accountSignUp: "/account/sign-up",
  accountSignUpInvited: "/account/sign-up-invited",
  accountForgotPassword: "/account/forgot-password",
  accountSetPassword: "/account/set-password",
  accountResetPassword: "/account/reset-password",
  accountVerifyEmail: "/account/verify-email",

  portal: "/portal",

  admin: "/portal/admin",
  adminDashboard: "/portal/admin/dashboard",
  adminDistributors: "/portal/admin/distributors",
  adminDistributorDetail: "/portal/admin/distributor-detail",
  adminDealers: "/portal/admin/dealers",
  adminDealerDetail: "/portal/admin/dealer-detail",
  adminMarketPlace: "/portal/admin/market-place",
  adminFAQ: "/portal/admin/faq",
  adminDepot: "/portal/admin/depot",
  adminSetting: "/portal/admin/setting",
  adminNotifications: "/portal/admin/notifications",
  adminTheNews: "/portal/admin/the-news",

  dealer: "/portal/dealer",
  dealerDashboard: "/portal/dealer/dashboard",
  dealerSetting: "/portal/dealer/setting",
  dealerDistributor: "/portal/dealer/distributors",
  dealerDistributorDetails: "/portal/dealer/distributor-detail",
  dealerDelivery: "/portal/dealer/delivery",
  dealerDeliveryDetails: "/portal/dealer/delivery-details",
  dealerFAQ: "/portal/dealer/faq",
  dealerNotifications: "/portal/dealer/notifications",

  distributor: "/portal/distributor",
  distributorDashboard: "/portal/distributor/dashboard",
  distributorDealer: "/portal/distributor/dealers",
  distributorDelivery: "/portal/distributor/delivery",
  distributorDeliveryDetails: "/portal/distributor/delivery-details",
  distributorDeliveriesTrack: "/portal/distributor/deliveries-track",
  distributorDealerDetails: "/portal/distributor/dealer-details",
  distributorReport: "/portal/distributor/reports",
  distributorFAQ: "/portal/distributor/faq",
  distributorSetting: "/portal/distributor/setting",
  distributorMarketPlace: "/portal/distributor/market-place",
  distributorNotifications: "/portal/distributor/notifications",
};
export { ConfigLink };
