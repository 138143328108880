import React, { useState } from "react";

import ServiceApi from "../services/service.api";
import ContextApp from "../contexts/context.app";

function ProviderApp({ children }) {
  const [isAuth, setIsAuthState] = useState(ServiceApi.hasToken());
  const [userInfo, setUserInfo] = useState({});
  const [metaData, setMetaData] = useState({});

  const setIsAuth = (value) => {
    if (value) {
      ServiceApi.setToken();
    } else {
      ServiceApi.logout();
    }

    setIsAuthState(value);
  };

  const ctxApp = {
    isAuth,
    setIsAuth,
    userInfo,
    setUserInfo,
    metaData,
    setMetaData,
  };

  return <ContextApp.Provider value={ctxApp}>{children}</ContextApp.Provider>;
}

export default ProviderApp;
