import secureStorage from "../utils/SecureStorage";
import config from "../configs/config.app";

const ServiceStorage = {
  setToken: (accessToken, refreshToken) => {
    secureStorage.setItem(config.accessToken, accessToken);
    secureStorage.setItem(config.refreshToken, refreshToken);

    if (process.env.REACT_APP_DEVELOPMENT_MODE) {
      localStorage.setItem(config.accessToken + "_DEV", accessToken);
      localStorage.setItem(config.refreshToken + "_DEV", refreshToken);
    }
  },

  setUserInfo: (userInfo) => {
    secureStorage.setItem(config.userInfo, userInfo);

    if (process.env.REACT_APP_DEVELOPMENT_MODE) {
      localStorage.setItem(config.userInfo + "_DEV", JSON.stringify(userInfo));
    }
  },

  getAccessToken: () => {
    try {
      return secureStorage.getItem(config.accessToken);
    } catch (error) {
      console.error("error :>> ", error);
      secureStorage.clear();
      localStorage.clear();
      return undefined;
    }
  },

  getRefreshToken: () => {
    try {
      return secureStorage.getItem(config.refreshToken);
    } catch (error) {
      console.error("error :>> ", error);
      secureStorage.clear();
      localStorage.clear();
      return undefined;
    }
  },

  clearToken: () => {
    secureStorage.clear();
    localStorage.clear();
  },
};

export default ServiceStorage;
